<template>
    <div class="m-5 md:mx-0 flex-col justify-center items-center flex pt-6 sm:pt-0">
        <div>
            <slot name="logo" />
        </div>

        <div class="w-full md:w-96 sm:max-w-md border mt-6 px-6 py-4 bg-white shadow-md overflow-hidden rounded-lg">
            <slot />
        </div>
    </div>
</template>
